<!--
 * @Author: Banbri
 * @Date: 2021-05-07 18:03:10
 * @LastEditors: Banbri
 * @LastEditTime: 2021-06-09 16:28:55
 * @Description: 用户评价
-->
<template>
  <div id="comment">
    <!-- 头部 -->
    <div class="page-header">
      <div class="title">
        <router-link :to="'/goods/Details?productID=' + productID">
          {{product_name}}
        </router-link>
        <div class="list">
          <ul>
            <li>
              <router-link :to="'/goods/comment?productID=' + productID"
                >用户评价</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 头部END -->
    <!-- 中间 -->
    <div class="mid" v-if="comments.length > 0">
      <div class="top">
        <div class="title">热门评价</div>
      </div>
      <div class="content" v-for="(comment,index) in comments" :key="index">
        <div class="top">
          <img :src="$target+comment.avatar" alt="头像" width="47" />
          <div class="time">
            <span>{{comment.userName}}</span>
            <div class="times">{{comment.comment_time | dateFormat}}</div>
          </div>
        </div>
        <div class="comm">{{comment.comment_msg}}</div>
      </div>
    </div>
    <h2 v-else>该商品暂无评论</h2>
    <!-- 中间END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      productID: '',
      comments:[],
      product_name:''
    }
  },
  activated() {
    this.productID = this.$route.query.productID;
    this.$axios
      .post("/api/user/comment/getComments", {
        productID: this.productID
      })
      .then(res => {
        this.product_name = res.data.product_name;
        if (res.data.code === "001") {
          this.comments = res.data.comments;
        }else{
          this.comments = [];
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}
</script>

<style>
/* 头部CSS */
#comment .page-header {
  height: 64px;
  margin-top: -20px;
  z-index: 4;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);
}
#comment .page-header .title {
  width: 1225px;
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  font-weight: 400;
  color: #212121;
  margin: 0 auto;
}
#comment .page-header .title > a {
  color: #616161;
}
#comment .page-header .title > a:hover {
  color: #ff6700;
}
#comment .page-header .title p {
  float: left;
}
#comment .page-header .title .list {
  height: 64px;
  float: right;
}
#comment .page-header .title .list li {
  float: left;
  margin-left: 20px;
}
#comment .page-header .title .list li a {
  font-size: 14px;
  color: #616161;
}
#comment .page-header .title .list li a:hover {
  font-size: 14px;
  color: #ff6700;
}
/* 头部CSS END */
/* 中间部分CSS */
.el-main {
  background-color: #f5f5f5;
}
#comment .mid {
  width: 1225px;
  margin: 0 auto;
}
#comment .mid > .top {
  margin: 20px 0;
}
#comment .mid > .top .title {
  color: #616161;
  font-size: 22px;
  line-height: 40px;
}
#comment .mid .content {
  color: #333;
  width: 100%;
  background: #fff;
  margin: 0 0 15px;
  padding: 40px 40px 46px 103px;
  box-sizing: border-box;
  position: relative;
}
#comment .mid .content .top img {
  position: absolute;
  left: 40px;
  top: 40px;
  display: block;
}
#comment .mid .content .top .time {
  width: 100%;
  height: 44px;
}
#comment .mid .content .top .time span {
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  height: 20px;
  line-height: 18px;
  overflow: hidden;
  font-size: 16px;
  color: #8d665a;
  display: block;
}
#comment .mid .content .top .time .times {
  height: 20px;
  line-height: 20px;
  margin-top: 6px;
  font-size: 14px;
  color: #b0b0b0;
}
#comment .mid .content .comm {
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  display: inline-block;
  margin-top: 8px;
  color: #5e5e5e;
  font-size: 18px;
  line-height: 27px;
}
#comment h2{
  -webkit-box-direction: normal;
  color: #b0b0b0;
  font-size: 36px;
  text-align: center;
  margin-top: 50px;
}
</style>